import { RSISignals } from "@jtassin/money-services";
import { format } from "date-fns";
import React from "react";
import { ListRenderItemInfo, StyleSheet } from "react-native";
import View from "../../ui/View";
import Text from "../../ui/Text";
import { useTheme } from "react-native-paper";
import { FontAwesome } from "@expo/vector-icons";

interface EventProps {
  event: ListRenderItemInfo<
    ReturnType<typeof RSISignals.computeGains>["events"][number]
  >;
}

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "space-between",
    gap: 10
  },
});

export function Event({ event }: EventProps) {
  const theme = useTheme();
  if (event.item.type === "BUY") {
    return (
      <View style={styles.row}>
        <Text style={{ color: "green" }}>Achat</Text>
        <Text>{format(event.item.date, "dd/MM/yyyy")}</Text>
        <Text style={{ color: "green" }}>{event.item.buyValue.toFixed(2)}</Text>
        <FontAwesome name="arrow-right" style={{ color: theme.colors.onBackground }} />
      </View>
    );
  }
  return (
    <View style={styles.row}>
      <Text style={{ color: "red" }}>Vente</Text>
      <Text>{format(event.item.date, "dd/MM/yyyy")}</Text>
      <Text style={{ color: "green" }}>{event.item.buyValue.toFixed(2)}</Text>
      <FontAwesome name="arrow-right" style={{ color: theme.colors.onBackground }} />
      <Text style={{ color: "red" }}>{event.item.sellValue.toFixed(2)}</Text>
      <Text>Gain : </Text>
      <Text style={{ color: event.item.gain > 0 ? "green" : "red" }}>
        {(event.item.gain * 100).toFixed(2)}%
      </Text>
      <Text>{event.item.cause}</Text>
    </View>
  );
}
