import { FontAwesome } from "@expo/vector-icons";
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme, LinkingOptions,
  NavigationContainer,
  RouteProp as DefaultRouteProp
} from "@react-navigation/native";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp
} from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import React from "react";
import { Pressable } from "react-native";
import {
  MD3LightTheme as PaperDefaultTheme,
  MD3DarkTheme as PaperDarkTheme,
} from "react-native-paper";
import Colors from "../constants/Colors";
import { useAppNavigation, useAppRoute } from "../hooks/useAppNavigation";
import useColorScheme from "../hooks/useColorScheme";
import AccountingConnectScreen from "../screens/Accounting/AccountingConnectScreen";
import AccountingParamsScreen from "../screens/Accounting/AccountingParamsScreen";
import AccountsListScreen from "../screens/Accounting/AccountsListScreen";
import BilanScreen from "../screens/Accounting/BilanScreen";
import GroupsScreen from "../screens/Accounting/GroupsScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import StatsScreen from "../screens/Accounting/StatsScreen";
import StockDetailScreen from "../screens/Stocks/StockDetailScreen";
import TransactionsListScreen from "../screens/Accounting/TransactionsListScreen";
import TransactionsSearchScreen from "../screens/Accounting/TransactionsSearch";
import { TabsNavigator } from "./TabsNavigator";

interface Search {
  comment?: string;
  accountIds?: string;
  userIds?: string;
  postIds?: string;
  groupIds?: string;
  contextIds?: string;
}

/**
 * the key is the name of the screen, the value the props of the screen
 */
export type NavigationParamsList = {
  // always undefined, used only for tabs wrapper
  tabs: undefined;
  notFound: undefined;
  accounting: undefined;
  stocks: undefined;
  "accounting.transactions": Search | undefined;
  "accounting.bilan": undefined;
  "accounting.accounts": undefined;
  "accounting.stats": { statId?: string };
  "accounting.groups": undefined;
  "accounting.connect": { code: string; connection_id: string };
  "accounting.search": Search | undefined;
  "accounting.params": undefined;
  "stocks.detail": { stockId: string };
  profile: undefined;
};

export type NavigationProp<
  T extends keyof NavigationParamsList | never = never
> = NativeStackNavigationProp<NavigationParamsList, T>;
export type RouteProp<T extends keyof NavigationParamsList> = DefaultRouteProp<
  NavigationParamsList,
  T
>;

const Stack = createNativeStackNavigator<NavigationParamsList>();

/**
 * Define linking only for deep link or notif intent
 */
const linking: LinkingOptions<NavigationParamsList> = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      tabs: {
        path: "",
        screens: {
          accounting: "accounting",
          stocks: "stocks",
        },
      },
      "accounting.transactions": "accounting/transactions",
      "accounting.bilan": "accounting/bilan",
      "accounting.accounts": "accounting/accounts",
      "accounting.stats": "accounting/stats",
      "accounting.groups": "accounting/groups",
      "accounting.connect": "accounting/connect",
      "accounting.search": "accounting/search",
      "accounting.params": "accounting/params",
      "stocks.detail": "stocks/:stockId",
      notFound: "*",
    },
  },
};

const CombinedDefaultTheme = {
  ...PaperDefaultTheme,
  ...NavigationDefaultTheme,
  colors: {
    ...PaperDefaultTheme.colors,
    ...NavigationDefaultTheme.colors,
    card: "lightblue",
  },
};
const CombinedDarkTheme = {
  ...PaperDarkTheme,
  ...NavigationDarkTheme,
  colors: {
    ...PaperDarkTheme.colors,
    ...NavigationDarkTheme.colors,
    card: "#435273",
  },
};

export function RootNavigator() {
  const colorScheme = useColorScheme();

  return (
    <NavigationContainer
      linking={linking}
      theme={colorScheme === "dark" ? CombinedDarkTheme : CombinedDefaultTheme}
    >
      <Stack.Navigator
        screenOptions={{
          headerShown: true,
        }}
      >
        <Stack.Screen
          name="tabs"
          component={TabsNavigator}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="accounting.transactions"
          options={{
            title: "Liste des transactions",
            headerRight: () => {
              const route = useAppRoute<"accounting.transactions">();
              const navigation = useAppNavigation<"accounting.transactions">();
              return (
                <Pressable
                  onPress={() =>
                    navigation.navigate("accounting.search", route.params)
                  }
                  style={({ pressed }) => ({
                    opacity: pressed ? 0.5 : 1,
                  })}
                >
                  <FontAwesome
                    name="search"
                    size={25}
                    color={Colors[colorScheme].text}
                    style={{ marginRight: 15 }}
                  />
                </Pressable>
              );
            },
          }}
          component={TransactionsListScreen}
        />
        <Stack.Screen
          name="accounting.bilan"
          component={BilanScreen}
          options={{
            title: "Bilan",
          }}
        />
        <Stack.Screen
          name="accounting.params"
          component={AccountingParamsScreen}
          options={{
            title: "Params",
          }}
        />
        <Stack.Screen
          options={{
            title: "Liste de comptes",
          }}
          name="accounting.accounts"
          component={AccountsListScreen}
        />
        <Stack.Screen
          name="accounting.stats"
          component={StatsScreen}
          options={{
            title: "Stats",
          }}
        />
        <Stack.Screen
          name="accounting.search"
          component={TransactionsSearchScreen}
          options={{
            title: "Rechercher des transactions",
          }}
        />
        <Stack.Screen
          name="accounting.groups"
          component={GroupsScreen}
          options={{
            title: "Liste des groupes",
          }}
        />
        <Stack.Screen
          name="stocks.detail"
          component={StockDetailScreen}
          options={{
            title: "Détails",
          }}
        />
        <Stack.Screen
          name="notFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
        <Stack.Screen
          name="accounting.connect"
          component={AccountingConnectScreen}
          options={{
            title: "Connection en cours....",
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
