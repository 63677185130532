import React from "react";
import { useFormContext } from "react-hook-form";
import {
  TextInput as NativeTextInput,
  TextInputProps as NativeTextInputProps,
} from "react-native";
import { TextInput } from "react-native-paper";

interface TextInputFormProps extends NativeTextInputProps {
  name: string;
  required?: boolean;
}

const TextInputForm = React.forwardRef<NativeTextInput, TextInputFormProps>(
  ({ name, required }, ref) => {
    const {
      register,
      setValue,
      getValues,
      formState: { errors },
    } = useFormContext();
    const value = getValues()[name];
    const { ...form } = register(name, {
      required,
      onChange: (text) => {
      },
    });
    return (
      <TextInput
        {...form}
        value={value}
        //@ts-expect-error
        ref={ref?.current}
        onChangeText={(text) => {
          setValue(name, text);
        }}
        error={Boolean(errors[name])}
      />
    );
  }
);

export default TextInputForm;
