import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, View, ViewStyle } from "react-native";
import { Chip, useTheme } from "react-native-paper";
//@ts-expect-error types does not exist
import { RefreshControl } from "react-native-web-refresh-control";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia
} from "rn-placeholder";
import {
  TransactionFieldsFragment,
  useTransactionsQuery
} from "../../../generated/graphql";
import { useAppNavigation, useAppRoute } from "../../../hooks/useAppNavigation";
import { logger } from "../../../services/logger";
import TransactionItem from "./TransactionItem";
import React from "react";

export default function TransactionsList({ style }: { style: ViewStyle }) {
  const route = useAppRoute<'accounting.transactions'>();
  const navigation = useAppNavigation();
  const comment = route.params?.comment;
  const postIds = route.params?.postIds;
  const contextIds = route.params?.contextIds;
  const userIds = route.params?.userIds;
  const accountIds = route.params?.accountIds;
  const groupIds = route.params?.groupIds;

  const search = {
    comment,
    postIds,
    contextIds,
    userIds,
    accountIds,
    groupIds,
  };
  const backendSearch = {
    ...(comment ? { comment } : {}),
    ...(postIds ? { postIds: [postIds] } : {}),
    ...(contextIds ? { contextIds: [contextIds] } : {}),
    ...(groupIds ? { groupIds: [groupIds] } : {}),
    ...(userIds ? { userIds: [userIds] } : {}),
    ...(accountIds ? { accountIds: [accountIds] } : {}),
  }
  const {
    loading,
    data: transactions,
    refetch,
    updateQuery,
  } = useTransactionsQuery({
    variables: backendSearch,
  });
  const theme = useTheme();
  const [reloading, setReloading] = useState(false);
  const [currentNewTransaction, setCurrentNewTransaction] = useState<
    Partial<TransactionFieldsFragment>
  >({
    date: new Date().toISOString(),
    checked: true,
  });
  const { t } = useTranslation();

  if (loading || !transactions) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  const unshiftNewTransaction = (transaction: TransactionFieldsFragment) => {
    updateQuery((previous) => ({
      ...previous,
      uncheckedTransactions: {
        items: [transaction, ...previous.uncheckedTransactions.items],
      },
    }));
  };

  const allTransactions = [
    currentNewTransaction,
    ...transactions.uncheckedTransactions.items,
    ...transactions.listTransactions.items,
  ];

  return (
    <>
      {comment ? (
        <Chip
        icon="close"
          onPress={() => {
            const newSearch = { ...search };
            delete newSearch.comment;
            navigation.navigate("accounting.transactions", newSearch);
          }}
        >
          {t("Commentaire : ")}
          {comment}
        </Chip>
      ) : null}
      {postIds ? (
        <Chip
          icon="close"
          onPress={() => {
            const newSearch = { ...search };
            delete newSearch.postIds;
            navigation.navigate("accounting.transactions", newSearch);
          }}
        >
          {t("Filtre sur poste")}
        </Chip>
      ) : null}
      {contextIds ? (
        <Chip
          icon="close"
          onPress={() => {
            const newSearch = { ...search };
            delete newSearch.contextIds;
            navigation.navigate("accounting.transactions", newSearch);
          }}
        >
          {t("Filtre sur catégorie")}
        </Chip>
      ) : null}
      {userIds ? (
        <Chip
          icon="close"
          onPress={() => {
            const newSearch = { ...search };
            delete newSearch.userIds;
            navigation.navigate("accounting.transactions", newSearch);
          }}
        >
          {t("Filtre sur personne")}
        </Chip>
      ) : null}
      {accountIds ? (
        <Chip
        icon="close"
          onPress={() => {
            const newSearch = { ...search };
            delete newSearch.accountIds;
            navigation.navigate("accounting.transactions", newSearch);
          }}
        >
          {t("Filtre sur compte")}
        </Chip>
      ) : null}
      {groupIds ? (
        <Chip
        icon="close"
          onPress={() => {
            const newSearch = { ...search };
            delete newSearch.groupIds;
            navigation.navigate("accounting.transactions", newSearch);
          }}
        >
          {t("Filtre sur foyer")}
        </Chip>
      ) : null}
      <FlatList
        style={style}
        ItemSeparatorComponent={() => {
          return (
            <View
              style={{
                height: 1,
                width: "100%",
                backgroundColor: theme.colors.background,
              }}
            ></View>
          );
        }}
        refreshControl={
          <RefreshControl
            refreshing={reloading}
            onRefresh={() => {
              setReloading(true);
              logger.debug("refresh transactions list");
              refetch().finally(() => {
                setReloading(false);
              });
            }}
          />
        }
        data={allTransactions}
        renderItem={(item) => {
          return (
            <TransactionItem
              //@ts-expect-error
              transaction={item.item}
              editOnly={false}
              onCreate={(transaction) => {
                unshiftNewTransaction(transaction);
                setCurrentNewTransaction({ ...transaction, id: undefined });
              }}
              onDuplicate={(transaction) => {
                unshiftNewTransaction(transaction);
              }}
            />
          );
        }}
      ></FlatList>
    </>
  );
}

const styles = StyleSheet.create({
  multiLines: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
