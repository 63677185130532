import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text } from "react-native";
import { DataTable } from "react-native-paper";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import { useBalancesQuery } from "../../../../generated/graphql";

export default function InnerBalances() {
  const { i18n } = useTranslation();
  const { loading, data, refetch } = useBalancesQuery();

  if (loading || !data) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  return (
    <DataTable>
      {data.groups
        .filter((group) => group.balances.length > 1)
        .map((group) => {
          const min = Math.min(...group.balances.map((b) => b.sum));
          return (
            <React.Fragment key={group.id}>
              <DataTable.Header key={group.id}>
                <DataTable.Title>
                  <Text>{group.name}</Text>
                </DataTable.Title>
              </DataTable.Header>
              {sortBy(group.balances, "user.firstname").map((balance) => (
                <DataTable.Row key={balance.id}>
                  <DataTable.Cell>
                    <Text>{balance.user.firstname}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <Text>
                      {new Intl.NumberFormat(i18n.language, {
                        minimumFractionDigits: 2,
                      }).format(Math.round((balance.sum - min) / 100))}{" "}
                      €
                    </Text>
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
            </React.Fragment>
          );
        })}
    </DataTable>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    width: "100%",
    height: "20%",
  },
});
